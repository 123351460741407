import { Tween } from "react-gsap";

const FadeInUp = ({
  stagger = { each: 0.3, from: 0 },
  duration = 1.5,
  delay = 0,
  children,
}) => {

  return (
      <Tween
        from={{ opacity: 0, yPercent: 100 }}
        ease="power4.out"
        stagger={stagger}
        duration={duration}
        delay={delay}
      >
        {children}
      </Tween>
  )
};

export default FadeInUp;
