import React from "react";
import "./TextDecoration.scss";

const DecorationText = ({ color, top, className }) => {
  return (
    <span className={`text-decoration-container ${className}`}>
      <svg
        style={{ "--top": `${top}` }}
        className="text-decoration"
        width="22px"
        height="12px"
        viewBox="0 0 12 12"
        version="1.1"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="📱Mobile/Home"
            transform="translate(-202.000000, -609.000000)"
            fill={`${color ? color : "#FFFFFF"}`}
          >
            <g id="Group-5-Copy" transform="translate(202.000000, 609.000000)">
              <path
                d="M11.8584186,6.9694406 C11.5784477,6.50989392 10.9720966,6.36212182 10.5108455,6.64105817 L4.6450948,10.1879659 C4.18384366,10.4669023 4.03552347,11.0710127 4.31549439,11.5305594 C4.59527589,11.9901061 5.20162698,12.1378782 5.66287813,11.8589418 L11.5288182,8.31203408 C11.9900694,8.03309773 12.1383896,7.42898729 11.8584186,6.9694406"
                id="Fill-16"
              ></path>
              <path
                d="M0.485765433,7.38048346 L0.485765433,7.38048346 C0.944632292,7.63158146 1.53251497,7.47397359 1.7920274,7.02998504 L5.0938697,1.38350378 C5.35338214,0.939515233 5.19049273,0.370694081 4.73162587,0.119596079 C4.27275901,-0.131681023 3.68487634,0.0261059526 3.4253639,0.470094503 L0.123521607,6.11657576 C-0.135990833,6.56056431 0.026898573,7.12938546 0.485765433,7.38048346"
                id="Fill-18"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

export default DecorationText;
