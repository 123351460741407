import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home";
// import OnlineOrder from "./pages/OnlineOrder";

function App() {
  return (
    <>
      
      <Router>
        <div className="App">
          <Route exact path="/" component={Home} />
          {/*<Route exact path="/order" component={OnlineOrder} />*/}
        </div>
      </Router>
    </>
  );
}

export default App;
