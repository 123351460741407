import React, { useState } from "react";
// import { Link } from 'react-router-dom'
import { Reveal } from "react-gsap";

import Nav from "../components/Nav";
import Heading from "../components/Heading";
import ModalView from "../components/ModalView";
import SectionCoverImage from "../components/SectionCoverImage";
import SectionColor from "../components/SectionColor";
import Footer from "../components/Footer";

// import HowToOrder from "../content-parts/HowToOrder";

import ImageFeastDesktop from "../assets/images/2880x1640-Desktop-Hero.jpg";
import ImageFeastMobile from "../assets/images/750x600-Mobile-Hero.jpg";
import ImageOurPurposeDesktop from "../assets/images/Desktop-our-purpose.jpg";
import ImageOurPurposeMobile from "../assets/images/Mobile-our-purpose.jpg";
import ImageLogoPitaPit from "../assets/images/logo-pitapit.png";
import ImageLogoEggd from "../assets/images/logo-eggd.png";
import ImageLogoMexicali from "../assets/images/logo-mexicali.png";
import ImageLogoBurgerWisconsin from "../assets/images/logo-burger-wisconsin.png";
import findUsDataLeft from "../data/findUsDataLeft.json";
import faqData from "../data/faqData.json";

import AccordionList from "../components/AccordionList";
import AccordionListWrapper from "../components/AccordionListWrapper";
import TextDecoration from "../components/TextDecoration";
import FadeInUp from "../components/FadeInUp";
import checked from "../assets/images/checkered.svg"

// import ModalStart from "../components/ModalStart"

export default function Home() {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showMoreFAQs] = useState(false);
  const [showStartModal, setShowStartModal] = useState(true);

  return (
    <>
      <Nav />
      {/* <ModalStart showModal={showStartModal} toggle={setShowStartModal} /> */}
      <SectionCoverImage
        id="order"
        extraTopPadding
        imageDesktopUrl={ImageFeastDesktop}
        imageMobileUrl={ImageFeastMobile}
        parallax
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <Reveal>
          <div className="d-flex flex-column align-items-center">
            <FadeInUp>
              <Heading
                headingSize="h1"
                upperCase
                textShadow
                className="text-light text-center fadeIn"
              >
                Eat one for
                <TextDecoration />
                <br /> the team
              </Heading>
            </FadeInUp>
            {/* <FadeInUp delay={0.2}>
              <Link
                to="/order"
                className="d-none d-lg-block btn btn-link btn-lg btn-primary text-light rounded-pill mt-5 px-5 py-3"
              >
                <span className="hover-label">Order Now</span>
              </Link>
            </FadeInUp> */}
          </div>
        </Reveal>
      </SectionCoverImage>

      {/* <SectionCoverVideo
        imageMobileUrl={ImageDarkFeastDesktop}
        videoDesktopUrl={VideoBackground}
        className="d-flex flex-column justify-content-center align-items-center"
      >
		<Reveal>
			<div className="d-flex flex-column align-items-center">
			<FadeInUp>
				<Heading
				headingSize="h1"
				upperCase
				className="text-light text-center"
				>
				Eat one for
				<TextDecoration />
				<br /> the team
				</Heading>
			</FadeInUp>
			<FadeInUp delay={0.2}>
				<a
				href="/order"
				className="d-none d-lg-block btn btn-link btn-lg btn-light rounded-pill mt-5"
				>
				<span className="hover-label">Order Now</span>
				</a>
			</FadeInUp>
			</div>
		</Reveal>
      </SectionCoverVideo> */}


      {/* <SectionSlider id="our-story" carouselData={ourStoryData} /> */}

      <SectionCoverImage
        id="our-purpose"
        parallax
        imageDesktopUrl={ImageOurPurposeDesktop}
        imageMobileUrl={ImageOurPurposeMobile}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <Reveal>
          <div className="container">
            <div className="col-lg-8 offset-lg-2 d-flex flex-column align-items-center">
              <FadeInUp>
                <Heading
                  headingSize="h2"
                  upperCase
                  className="text-light text-center"
                >
                  Our purpose
                  <TextDecoration />
                </Heading>
                <Heading headingSize='h3' className="mt-4 text-center text-light fw-bold">
                  Fuelling Clubs, Schools, and Feeding Communities
                </Heading>
                <p className="text-light text-center mt-4">
                  Move over sausage sizzle, park that quiz night, there’s a tastier, better way to fundraise for your club or school canteen.
                  It’s called Club Grub and has been developed to provide tools and meal options for sports clubs and high schools.
                  This helps to allow the canteen to reach it’s FULL potential.
                </p>
                <p className="text-light text-center mt-4">
                  Club Grub helps fuel your players, students, staff, and supporters with the best-tasting, and most nutritious meal options.
                  Club Grub is a social enterprise that provides an exciting income opportunity for the host club or school.
                  All meals are made fresh from the kitchen. Convenient and easy, Club Grub creates jobs for members and students too.
                </p>
                <p className="text-light text-center mt-4">
                  When you eat one for the team, everyone wins – students, teams, clubs, supporters, customers, and communities.
                </p>

                {/* <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-link text-light mt-4 me-2"
                    onClick={() => setShowVideoModal(!showVideoModal)}
                  >
                    Watch Video
                  </button>
                  <button
                    className="btn btn-outline-secondary btn-circle mt-4"
                    onClick={() => setShowVideoModal(!showVideoModal)}
                  >
                    <span className="hover-label">
                      <i className="far fa-play ms-1"></i>
                    </span>
                  </button>
                </div> */}
              </FadeInUp>
              <ModalView
                showModal={showVideoModal}
                toggle={setShowVideoModal}
                video
                url="https://www.youtube.com/embed/K1QICrgxTjA"
              />
            </div>
          </div>
        </Reveal>
      </SectionCoverImage>

      <SectionCoverImage
        id="about-us"
        parallax
        imageDesktopUrl={null}
        imageMobileUrl={null}
        className="d-flex flex-column justify-content-center align-items-center bg-primary"
      >
        <Reveal>
          <div className="container">
            <div className="col-lg-8 offset-lg-2 d-flex flex-column align-items-center">
              <FadeInUp>
                <Heading
                  headingSize="h2"
                  upperCase
                  className="text-light text-center"
                >
                  About Us
                  <TextDecoration />
                </Heading>
                <p className="text-light text-center mt-4">
                  Club Grub was created to give school canteens and sports clubs a boost.
                </p>
                <p className="text-light text-center mt-4">
                  Club Grub does this by providing an exciting income opportunity and supporting their communities with jobs,
                  social connections and delicious meals. Club Grub exclusively occupies the canteen or club kitchen and is a digitally-led ordering experience.
                </p>
                <p className="text-light text-center mt-4">
                  Customers simply order and pay for their favourite meals made fresh in the kitchen or canteen via their phones, devices, kiosks or from the
                  location on easy-to-use tablets located conveniently for students, staff or club members.
                </p>
                <p className="text-light text-center mt-4">
                  Meal options for each club or school include a selection of freshly made pitas, burgers, rice dishes, sushi, breakfast brioche, snacks, drinks plus much more
                </p>



              </FadeInUp>
            </div>
          </div>
        </Reveal>
      </SectionCoverImage>

      <div style={{backgroundImage: `url(${checked})`, backgroundRepeat: 'repeat', width: `100%`, height: `42px`, backgroundColor: '#ffffff' }}></div >

      {/*<SectionColor id="the-brands" bgColor="white">
        <Reveal>
          <div className="container">
            <FadeInUp>
              <Heading
                headingSize="h2"
                upperCase
                className="text-center mb-4 mb-md-5"
              >
                Powered By
                <TextDecoration color="#E73C3E" />
              </Heading>
            </FadeInUp>
            <div className="row">
              <FadeInUp className="row">
                <div className="col-6 col-md-3 d-flex align-items-center justify-content-center mb-3 mb-lg-0">
                <a href="https://www.pitapit.co.nz/" target="_blank" rel="noreferrer">
                  <img
                    src={ImageLogoPitaPit}
                    alt="PitaPit"
                    className="img-fluid"
                    width="173"
                  />
                </a>
                </div>
                <div className="col-6 col-md-3 d-flex align-items-center justify-content-center mb-3 mb-lg-0">
                <a href="https://www.facebook.com/eggdnz/" target="_blank" rel="noreferrer">
                  <img
                    src={ImageLogoEggd}
                    alt="Egg'd"
                    className="img-fluid"
                    width="173"
                  />
                </a>
                </div>
                <div className="col-6 col-md-3 d-flex align-items-center justify-content-center mb-3 mb-lg-0">
                <a href="https://www.mexicalifresh.co.nz/" target="_blank" rel="noreferrer">
                  <img
                    src={ImageLogoMexicali}
                    alt="Mexicali"
                    className="img-fluid"
                    width="173"
                  />
                </a>
                </div>
                <div className="col-6 col-md-3 d-flex align-items-center justify-content-center mb-3 mb-lg-0">
                <a href="https://www.burgerwisconsin.co.nz/" target="_blank" rel="noreferrer">
                  <img
                    src={ImageLogoBurgerWisconsin}
                    alt="Burger Wisconsin"
                    className="img-fluid"
                    width="173"
                  />
                </a>
                </div>
              </FadeInUp>
            </div>
          </div>
        </Reveal>
      </SectionColor>*/}

      {/*<SectionColor id="how-to-order" bgColor="#0857C3" className="gx-0">
        <Reveal>
          <div>
            <FadeInUp>
              <Heading
                headingSize="h2"
                upperCase
                className="text-center text-light mb-3 mb-md-5"
              >
                How it Works
              </Heading>
              <Heading 
                headingSize='h3'
                className="text-center text-light mb-3 mb-md-5 px-4"
              >
                Order from your favourite food brands on our online marketplace
              </Heading>
            </FadeInUp>
            <HowToOrder />
          </div>
        </Reveal>
      </SectionColor>*/}

      {/*<SectionColor id="faq" bgColor="white">
        <Reveal>
          <div className="container">
            <FadeInUp>
              <Heading headingSize="h2" className="text-center pb-4 pb-md-5">
                FAQ's
                <TextDecoration color="#E73C3E" top="25px" />
              </Heading>
            </FadeInUp>
            <FadeInUp>
              <div>
                <AccordionList data={faqData.slice(0, 3)} />
                <AccordionListWrapper showMore={showMoreFAQs}>
                  <AccordionList data={faqData.slice(3, faqData.length)} />
                </AccordionListWrapper>
              </div>
            </FadeInUp>
          </div>
        </Reveal>
      </SectionColor>*/}

      <SectionColor id="find-us" bgColor="#E73C3E">
        <Reveal>
          <div className="container px-md-5">
            <FadeInUp threshold={0}>
              <Heading
                headingSize="h2"
                upperCase
                className="text-center text-light pb-4 pb-md-5"
              >
                Find us
                <TextDecoration top="10px" />
              </Heading>
              <div className="row">
                <div className="col-12 col-lg-12">
                  <AccordionList accLight data={findUsDataLeft} defaultActiveKey={0} />
                </div>
                {/* <div className="col-12 col-lg-6">
                  <AccordionList accLight data={findUsDataRight} />
                </div> */}
              </div>
            </FadeInUp>
          </div>
        </Reveal>
      </SectionColor>

      <Footer />
    </>
  );
}
