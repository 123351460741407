import { forwardRef } from "react";
import "./SectionCoverImage.scss";
import Container from "react-bootstrap/Container";
import ClassNames from "classnames";

const SectionCoverImage = forwardRef(
  (
    {
      id,
      parallax,
      extraTopPadding,
      imageDesktopUrl,
      imageMobileUrl,
      className = "",
      children,
    },
    ref
  ) => {
    const CLASS_NAMES = ClassNames(
      "section-cover-image",
      { "section-cover-image-extra-padding": extraTopPadding },
      { "parallax": parallax },
      className
    );

    return (
      <>
        <Container
          id={id}
          ref={ref}
          fluid
          className={CLASS_NAMES}
          style={{
            "--desktopBGimg": `url(${imageDesktopUrl})`,
            "--mobileBGimg": `url(${imageMobileUrl})`,
          }}
        >
          {children}
        </Container>
      </>
    );
  }
);

export default SectionCoverImage;
