import { forwardRef } from "react";
import ClassNames from "classnames";
import "./Heading.scss"

const Heading = forwardRef(
  ({ headingSize, upperCase, textShadow, className = "", children }, ref) => {
    const getLevel = () => {
      if (
        headingSize === "h1" ||
        headingSize === "h2" ||
        headingSize === "h3" ||
        headingSize === "h4" ||
        headingSize === "h5" ||
        headingSize === "h6"
      ) {
        return headingSize;
      }

      return "h1";
    };

    const CLASS_NAMES = ClassNames(
      {
        "text-uppercase": upperCase,
        "text-shadow": textShadow,
      },
      className
    );

    const Component = getLevel();

    return (
      <Component className={CLASS_NAMES} ref={ref}>
        {children}
      </Component>
    );
  }
);

Heading.displayName = "Heading";

export default Heading;
