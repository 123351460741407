import { useState, useEffect } from "react";
import ClassNames from "classnames";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Heading from "../Heading";
import CloseBtn from "../../assets/images/icon-close.svg";
import CloseBtnWhite from "../../assets/images/icon-close-white.svg";
import "./ModalView.scss";
import TextDecoration from "../TextDecoration";

const ModalView = ({
  showModal,
  toggle,
  joinUs,
  joinSchool,
  contact,
  video,
  url = "https://www.youtube.com/embed/K1QICrgxTjA",
}) => {
  const [show, setShow] = useState(showModal);
  const [rating, setRating] = useState(4);
  const [hasOnsiteKitchen, setHasOnsiteKitchen] = useState("Yes");

  const EMOJI_CLASSNAMES = ClassNames(
    "far",
    { "fa-frown-open": rating <= 1 },
    { "fa-frown": rating === 2 },
    { "fa-meh": rating === 3 },
    { "fa-smile": rating === 4 },
    { "fa-smile-beam": rating === 5 }
  );

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  if (joinUs) {
    return (
      <Modal
        className="rounded"
        show={show}
        onHide={toggle}
        size="xl"
        fullscreen='lg-down'
        centered
      >
        <Modal.Body className="d-flex flex-column">
          <div className="d-flex flex-column justify-content-center align-items-center p-5 pb-0 border-0">
            <div className="w-100 d-flex justify-content-end position-absolute top-0 p-2">
              <div className="btn rounded-circle" onClick={() => toggle()}>
                <img
                  src={CloseBtn}
                  alt="Close Button"
                  className="rounded-circle"
                />
              </div>
            </div>

            <Heading headingSize="h2" upperCase className="mb-4 text-center">
              Club Registration
              <TextDecoration color='var(--bs-red)' />
            </Heading>
            <p className="text-center">
              Want to see Club Grub at your local Sports Club? Submit your details below to enquire.
            </p>
          </div>
          <Form
            className="p-4 p-lg-5 kwes-form"
            success-message='Thank you for your enquiry. A team member will be in touch with you soon.'
            action="https://kwesforms.com/api/foreign/forms/glySlQb9lSmFYm1G2xa0"
          >
            <Row className="mb-4">
              <Col
                md={12}
                lg={6}
              >
                <FloatingLabel label="First Name">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    type="text"
                    placeholder="First Name"
                    name='firstName'
                    rules='required'
                  />
                </FloatingLabel>
              </Col>
              <Col
                md={12}
                lg={6}
                className="mt-4 mt-lg-0"
              >
                <FloatingLabel label="Last Name">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    type="text"
                    placeholder="Last Name"
                    name='lastName'
                    rules='required'
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Col className="mb-4">
              <FloatingLabel label="Club Name">
                <Form.Control
                  className="border-start-0 border-top-0 border-end-0"
                  placeholder="Club Name"
                  name='clubName'
                  rules='required'
                />
              </FloatingLabel>
            </Col>
            <Col className="mb-4">
              <FloatingLabel label="Club Address">
                <Form.Control
                  className="border-start-0 border-top-0 border-end-0"
                  placeholder="Club Address"
                  name='clubAddress'
                  rules='required'
                />
              </FloatingLabel>
            </Col>

            <Row className="mb-4">
              <Col>
                <p>
                  Does your Club currently have an onsite commercial kitchen?
                </p>
                <Form.Control
                  type='hidden'
                  name='onSiteKitchen'
                  value={hasOnsiteKitchen}
                />
                <Button
                  className="rounded border-3 py-3 px-5 me-3"
                  variant={hasOnsiteKitchen === "Yes" ? "primary" : "outline-primary btn-light"}
                  size="lg"
                  onClick={() => setHasOnsiteKitchen("Yes")}
                >
                  <span className="hover-label">
                    Yes
                  </span>
                </Button>
                <Button
                  className="rounded border-3 py-3 px-5 me-3"
                  variant={hasOnsiteKitchen === "No" ? "primary" : "outline-primary btn-light"}
                  size="lg"
                  onClick={() => setHasOnsiteKitchen("No")}
                >
                  <span className="hover-label">
                    No
                  </span>
                </Button>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col
                md={12}
                lg={6}
              >
                <FloatingLabel label="Your relationship with the Club">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    placeholder="Your relationship with the Club"
                    name='relationshipWithClub'
                    rules='required'
                  />
                </FloatingLabel>
              </Col>
              <Col
                md={12}
                lg={6}
                className="mt-4 mt-lg-0"
              >
                <FloatingLabel label="Contact No">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    placeholder="Contact No"
                    name='contactNo'
                    type='tel'
                    rules='required'
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Row>
              <Col
                md={12}
                lg={6}
              >
                <FloatingLabel label="Email Address">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    placeholder="Email Address"
                    name='email'
                    rules='required|email|confirmed'
                  />
                </FloatingLabel>
              </Col>
              <Col
                md={12}
                lg={6}
                className="mt-4 mt-lg-0"
              >
                <FloatingLabel label="Confirm Email Address">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    placeholder="Confirm Email Address"
                    name='email_confirmation'
                    rules='required|email'
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center mt-5">
              <Button
                className="col-md-auto rounded-pill border-2 px-4"
                variant="outline-primary"
                size="lg"
                type="submit"
              >
                <span className="hover-label">
                  Submit
                </span>
              </Button>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
  if (joinSchool) {
    return (
      <Modal
        className="rounded"
        show={show}
        onHide={toggle}
        size="xl"
        fullscreen='lg-down'
        centered
      >
        <Modal.Body className="d-flex flex-column">
          <div className="d-flex flex-column justify-content-center align-items-center p-5 pb-0 border-0">
            <div className="w-100 d-flex justify-content-end position-absolute top-0 p-2">
              <div className="btn rounded-circle" onClick={() => toggle()}>
                <img
                  src={CloseBtn}
                  alt="Close Button"
                  className="rounded-circle"
                />
              </div>
            </div>

            <Heading headingSize="h2" upperCase className="mb-4 text-center">
              SCHOOL REGISTRATION
              <TextDecoration color='var(--bs-red)' />
            </Heading>
            <p className="text-center">
              Want to see Club Grub
              at your school? Submit your details below to enquire.
            </p>
          </div>
          <Form
            className="p-4 p-lg-5 kwes-form"
            success-message='Thank you for your enquiry. A team member will be in touch with you soon.'
            action="https://kwesforms.com/api/foreign/forms/glySlQb9lSmFYm1G2xa0"
          >
            <Row className="mb-4">
              <Col
                md={12}
                lg={6}
              >
                <FloatingLabel label="First Name">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    type="text"
                    placeholder="First Name"
                    name='firstName'
                    rules='required'
                  />
                </FloatingLabel>
              </Col>
              <Col
                md={12}
                lg={6}
                className="mt-4 mt-lg-0"
              >
                <FloatingLabel label="Last Name">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    type="text"
                    placeholder="Last Name"
                    name='lastName'
                    rules='required'
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Col className="mb-4">
              <FloatingLabel label="School Name">
                <Form.Control
                  className="border-start-0 border-top-0 border-end-0"
                  placeholder="School Name"
                  name='schoolName'
                  rules='required'
                />
              </FloatingLabel>
            </Col>
            <Col className="mb-4">
              <FloatingLabel label="School Address">
                <Form.Control
                  className="border-start-0 border-top-0 border-end-0"
                  placeholder="School Address"
                  name='schoolAddress'
                  rules='required'
                />
              </FloatingLabel>
            </Col>

            <Row className="mb-4">
              <Col>
                <p>
                  Does your School currently have an onsite commercial kitchen?
                </p>
                <Form.Control
                  type='hidden'
                  name='onSiteKitchen'
                  value={hasOnsiteKitchen}
                />
                <Button
                  className="rounded border-3 py-3 px-5 me-3"
                  variant={hasOnsiteKitchen === "Yes" ? "primary" : "outline-primary btn-light"}
                  size="lg"
                  onClick={() => setHasOnsiteKitchen("Yes")}
                >
                  <span className="hover-label">
                    Yes
                  </span>
                </Button>
                <Button
                  className="rounded border-3 py-3 px-5 me-3"
                  variant={hasOnsiteKitchen === "No" ? "primary" : "outline-primary btn-light"}
                  size="lg"
                  onClick={() => setHasOnsiteKitchen("No")}
                >
                  <span className="hover-label">
                    No
                  </span>
                </Button>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col
                md={12}
                lg={6}
              >
                <FloatingLabel label="Your relationship with the school">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    placeholder="Your relationship with the school"
                    name='relationshipWithschool'
                    rules='required'
                  />
                </FloatingLabel>
              </Col>
              <Col
                md={12}
                lg={6}
                className="mt-4 mt-lg-0"
              >
                <FloatingLabel label="Contact No">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    placeholder="Contact No"
                    name='contactNo'
                    type='tel'
                    rules='required'
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Row>
              <Col
                md={12}
                lg={6}
              >
                <FloatingLabel label="Email Address">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    placeholder="Email Address"
                    name='email'
                    rules='required|email|confirmed'
                  />
                </FloatingLabel>
              </Col>
              <Col
                md={12}
                lg={6}
                className="mt-4 mt-lg-0"
              >
                <FloatingLabel label="Confirm Email Address">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    placeholder="Confirm Email Address"
                    name='email_confirmation'
                    rules='required|email'
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center mt-5">
              <Button
                className="col-md-auto rounded-pill border-2 px-4"
                variant="outline-primary"
                size="lg"
                type="submit"
              >
                <span className="hover-label">
                  Submit
                </span>
              </Button>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  if (contact) {
    return (
      <Modal
        className="rounded"
        show={show}
        onHide={toggle}
        size="xl"
        centered
        fullscreen='lg-down'
      >

        <Modal.Body className="d-flex flex-column px-5">
          <div className="d-flex flex-column justify-content-center align-items-center p-5 pb-0 border-0">
            <div className="w-100 d-flex justify-content-end position-absolute top-0 p-2">
              <span className="btn rounded-circle" onClick={() => toggle()}>
                <img src={CloseBtn} alt="Close Button" />
              </span>
            </div>
            <Heading headingSize="h2" upperCase className="text-center my-4 mx-5">
              Customer<TextDecoration color='var(--bs-red)' className='mobile' /> Feedback<TextDecoration color='var(--bs-red)' className='desktop' />
            </Heading>
          </div>
          <Form
            className="p-4 p-lg-5 kwes-form"
            success-message='Thank you for your feedback. A team member will be in touch with you soon.'
            action="https://kwesforms.com/api/foreign/forms/t5jKCR7BzaJkwBJHMsF2"
          >
            <Row>
              <Col className="d-flex justify-content-center rating-emoji">
                <i className={EMOJI_CLASSNAMES}></i>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="text-center">
                {rating === 1 && <p>Very Dissatisfied</p>}
                {rating === 2 && <p>Dissatisfied</p>}
                {rating === 3 && <p>Satisfied</p>}
                {rating === 4 && <p>Very Satisfied</p>}
                {rating === 5 && <p>Extremely Satisfied</p>}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="d-flex justify-content-center rating">
                <input type="hidden" value={rating} name="rating" />
                <i
                  className={`${rating >= 1 ? "fas" : "far"} fa-star rating-btn`}
                  onClick={() => setRating(1)}
                />
                <i
                  className={`${rating >= 2 ? "fas" : "far"} fa-star rating-btn`}
                  onClick={() => setRating(2)}
                />
                <i
                  className={`${rating >= 3 ? "fas" : "far"} fa-star rating-btn`}
                  onClick={() => setRating(3)}
                />
                <i
                  className={`${rating >= 4 ? "fas" : "far"} fa-star rating-btn`}
                  onClick={() => setRating(4)}
                />
                <i
                  className={`${rating >= 5 ? "fas" : "far"} fa-star rating-btn`}
                  onClick={() => setRating(5)}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col
                md={12}
                lg={6}
              >
                <FloatingLabel label="Full Name">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    type="text"
                    placeholder="Full Name"
                    name="full-name"
                    rules="required"
                  />
                </FloatingLabel>
              </Col>
              <Col
                md={12}
                lg={6}
                className="mt-4 mt-lg-0"
              >
                <FloatingLabel label="Email">
                  <Form.Control
                    className="border-start-0 border-top-0 border-end-0"
                    type="email"
                    placeholder="Email"
                    name="email"
                    rules='required|email'
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col
                md={12}
                lg={6}
              >
                <div className="kw-datepicker-wrapper">
                  <FloatingLabel label="Date of Feedback">
                    <Form.Control
                      className="border-start-0 border-top-0 border-end-0"
                      type="datepicker"
                      placeholder="Date of Feedback"
                      name="date-of-feedback"
                      rules="required"
                      autoComplete="off"
                    />
                  </FloatingLabel>
                </div>
              </Col>
              <Col
                md={12}
                lg={6}
                className="mt-4 mt-lg-0"
              >
                <FloatingLabel className="">
                  <Form.Select
                    className="border-start-0 border-top-0 border-end-0 p-3 px-0"
                    placeholder="Club Location"
                    name="location"
                    rules="required"
                  >
                    <option value="">Club Location</option>
                    <option value="Silverdale Rugby and Sports Club">Silverdale Rugby and Sports Club</option>
                    <option value="Westlake Boys High School">Westlake Boys High School</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel label="Message goes here">
                  <Form.Control
                    as="textarea"
                    className="border-start-0 border-top-0 border-end-0 textarea"
                    placeholder="Message goes here"
                    name="message"
                    rules="required"
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center mt-5">
              <Button
                className="col-md-auto px-5 py-2 rounded-pill border-3"
                variant="outline-primary"
                type="submit"
              >
                <span className="hover-label">
                  Submit
                </span>
              </Button>
            </Row>
          </Form>
          <div className="border-0 text-center d-flex flex-column mb-5">
            <p>Club Grub Corporate</p>
            <p>Address: L3, 95 Hurstmere Rd, Takapuna, Auckland</p>
            PO Box: 331-471, Takapuna, Auckland 0470
            <p>
              Email:{" "}
              <a href="mailto:hello@clubgrub.co.nz">hello@clubgrub.co.nz</a>
            </p>
          </div>
        </Modal.Body>

      </Modal>
    );
  }

  if (video) {
    return (
      <Modal
        className="modal-video-bg embed-responsive embed-responsive-16by"
        show={show}
        onHide={toggle}
        size="xl"
        centered
      >
        <div className="w-100 d-flex justify-content-end position-absolute top-0 p-2 modal-video-close">
          <div className="btn rounded-circle" onClick={() => toggle()}>
            <img
              src={CloseBtnWhite}
              alt="Close Button"
              className="rounded-circle close-btn"
            />
          </div>
        </div>
        <iframe
          className="embed-responsive-item modal-video"
          title="video"
          src={url}
          allowFullScreen
        ></iframe>
      </Modal>
    );
  }

  return null;
};

export default ModalView;
