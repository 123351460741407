import React from "react";
import "./Footer.scss";

export default function Footer() {
  return (
    <footer className="section-footer">
      <div className="footer-content">
        <a
          href="#order"
          className="back-to-top btn btn-link btn-outline-primary btn-circle btn-light mb-3"
        >
          <span className="hover-label">
            <i className="far fa-long-arrow-up"></i>
          </span>
        </a>
        <ul className="social-icons">
          <li>
            <a
              href="https://www.facebook.com/clubgrubnz"
              target="_blank"
              rel="noreferrer"
              className="btn-link btn-round btn-light"
            >
              <span className="hover-label">
                <i className="fab fa-facebook"></i>
              </span>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/clubgrubnz/"
              target="_blank"
              rel="noreferrer"
              className="btn-link btn-round btn-light"
            >
              <span className="hover-label">
                <i className="fab fa-instagram"></i>
              </span>
            </a>
          </li>
        </ul>
        <small className="text-center fw-bold">
          {new Date().getFullYear()} &copy; Club Grub
        </small>
      </div>
      <div className="footer-print"></div>
    </footer>
  );
}
