import "./SectionColor.scss";
import Container from "react-bootstrap/Container";
import ClassNames from "classnames";

const SectionColor = ({ id, bgColor, className = "", children }) => {
  const CLASS_NAMES = ClassNames("section-color", className);

  return (
    <>
      <Container
        id={id}
        fluid
        className={CLASS_NAMES}
        style={{ "--bgColor": `${bgColor}` }}
      >
        {children}
      </Container>
    </>
  );
};

export default SectionColor;
