import Accordion from "react-bootstrap/Accordion";
import ClassNames from "classnames";
import "./AccordionList.scss";

const AccordionList = ({
  defaultActiveKey = null,
  accLight,
  className,
  data = [{ heading: "", body: "" }],
}) => {
  const CLASS_NAMES = ClassNames("acc", { "acc-light": accLight }, className);

  return (
    <Accordion className={CLASS_NAMES} defaultActiveKey={defaultActiveKey}>
      {data.map((item, index) => (
        <Accordion.Item key={index} eventKey={index}>
          <Accordion.Header>{item.heading}</Accordion.Header>
          <Accordion.Body dangerouslySetInnerHTML={{__html: item.body}} />
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default AccordionList;
