import { useState, useEffect, useCallback } from "react";
import Headroom from "react-headroom";
import { Container, Navbar, Nav } from "react-bootstrap";
import ScrollSpy from "bootstrap/js/src/scrollspy";

import "./Nav.scss";
import Logo from "../../assets/images/club-grub-logo.svg";
import ModalView from "../ModalView";
import { useLocation, Link } from 'react-router-dom'
import kwesforms from 'kwesforms';

export default function Navigation() {
  const [showJoinUs, setShowJoinUs] = useState(false);
  const [showJoinSchool, setShowJoinSchool] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [isNavActive, setIsNavActive] = useState(false);
  const [isDevice, setIsDevice] = useState(false);
  const location = useLocation()
  // eslint-disable-next-line 
  let modalLinkTimeout
  let formTimeout

  const handleWindowResize = useCallback(event => {
    if (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 992) {
      setIsDevice(false);
    } else {
      setIsDevice(true);
    }
  }, []);

  useEffect(() => {
    const scrollSpy = new ScrollSpy(document.body, {
      target: "#navbar",
    });
    return () => {
      scrollSpy.dispose()
      if (modalLinkTimeout) clearTimeout(modalLinkTimeout)
      if (formTimeout) clearTimeout(formTimeout)
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    isNavActive
      ? document.body.classList.add("menu-opened")
      : document.body.classList.remove("menu-opened");
  }, [isNavActive]);

  /* eslint-disable */
  useEffect(() => {
    if (showJoinUs || showContact || showJoinSchool) {
      kwesforms.init();
      console.log(kwesforms)
    }
  }, [])

  useEffect(() => {
    if (showJoinUs || showContact || showJoinSchool) {
      formTimeout = setTimeout(() => {
        kwesforms.init();
      }, 10)
    }
    return () => clearTimeout(formTimeout)
  }, [showJoinUs, showContact, showJoinSchool])
  /* eslint-enable */

  useEffect(() => {
    const removeHash = () => { 
      window.history.replaceState("", document.title, window.location.pathname + window.location.search);
    }
    const handleHash = () => {
      const { hash } = window.location
      if (hash.includes('#join-us')) {
        setShowJoinUs(true)
        removeHash()
      } else {
        setShowJoinUs(false)
      }
    }
    handleHash()
    window.addEventListener('hashchange', handleHash)
    return () => window.removeEventListener('hashchange', handleHash)
  }, [])

  const handleNavClick = (e, anchor) => {
    e.preventDefault();
    setIsNavActive(false);
    if (isDevice) {
      setTimeout(() => {
        window.location = anchor;
      }, 500);
    } else {
      window.location = anchor;
    }
  };

  const handleModal = (modalState, setModalState) => {
    setIsNavActive(false)
    modalLinkTimeout = setTimeout(() => {
      setModalState(!modalState)
    }, 400)
  }

  return (
    <>
      <Headroom>
        <Navbar
          id="navbar"
          top="0"
          expand="lg"
          className="navbar-wrapper navbar-dark justify-content-around px-2"
        >
          <Container className="justify-content-around">
            <div className="col-md-auto d-flex align-items-center justify-content-center">
              <Link to="/" style={{ zIndex: 10 }}>
                <img
                  src={Logo}
                  alt="Logo"
                  width="102"
                  className="brand-logo img-fluid"
                />
              </Link>
            </div>

            {/* <Link
              className="col-md-auto btn btn-link btn-light rounded-pill order-lg-last"
              style={{ zIndex: 10 }}
              to="/order"
            >
              <span className="hover-label">Order Now</span>
            </Link> */}

            <div className="col-auto d-lg-none d-flex justify-content-center">
              <div className="position-relative d-flex justify-content-center align-items-center">
                <span className="menu-circle"></span>
                <Navbar.Toggle
                  aria-controls="responsive-navbar-nav"
                  bsPrefix={`hamburger hamburger-circle hamburger--slider ${
                    isNavActive ? "is-active" : ""
                  }`}
                  onClick={() => setIsNavActive(!isNavActive)}
                  style={{ zIndex: 10 }}
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </Navbar.Toggle>
              </div>
            </div>

            <Navbar
              id="responsive-navbar-nav"
              className={`navbar-nav-wrapper col-md-auto px-3 ${
                isDevice && isNavActive ? "nav-device" : ""
              }`}
              style={{ zIndex: 10 }}
            >
              <Nav className="text-center mx-auto">
                <Nav.Link
                  href="#our-purpose"
                  className="animated fadeInUp"
                  onClick={(e) => handleNavClick(e, "#our-purpose")}
                >
                  <span className="hover-label">Our Purpose</span>
                </Nav.Link>
                <Nav.Link
                  href="#about-us"
                  className="animated fadeInUp"
                  onClick={(e) => handleNavClick(e, "#about-us")}
                >
                  <span className="hover-label">About Us</span>
                </Nav.Link>
                {/*<Nav.Link
                  href="#the-brands"
                  className="animated fadeInUp delay-1"
                  onClick={(e) => handleNavClick(e, "#the-brands")}
                >
                  <span className="hover-label">Our Brands</span>
                </Nav.Link>*/}
                {/*<Nav.Link
                  href="#how-to-order"
                  className="animated fadeInUp delay-2"
                  onClick={(e) => handleNavClick(e, "#how-to-order")}
                >
                  <span className="hover-label">How it Works</span>
                </Nav.Link>*/}
                <Nav.Link
                  href="#find-us"
                  className="animated fadeInUp delay-3"
                  onClick={(e) => handleNavClick(e, "#find-us")}
                >
                  <span className="hover-label">Find Us</span>
                </Nav.Link>
                <Nav.Link
                  href=""
                  className="animated fadeInUp delay-4"
                  onClick={() => handleModal(showContact, setShowContact)}
                >
                  <span className="hover-label">Feedback</span>
                </Nav.Link>
                <Nav.Link
                  href=""
                  className="animated fadeInUp delay-5"
                  onClick={() => handleModal(showJoinUs, setShowJoinUs)}
                >
                  <span className="hover-label">Club Registration</span>
                </Nav.Link>
                <Nav.Link
                  href=""
                  className="animated fadeInUp delay-5"
                  onClick={() => handleModal(showJoinSchool, setShowJoinSchool)}
                >
                  <span className="hover-label">School Registration</span>
                </Nav.Link>
              </Nav>
            </Navbar>
          </Container>
        </Navbar>
      </Headroom>
      <ModalView showModal={showJoinUs} toggle={setShowJoinUs} joinUs />
      <ModalView showModal={showJoinSchool} toggle={setShowJoinSchool} joinSchool />
      <ModalView showModal={showContact} toggle={setShowContact} contact />
    </>
  );
}
